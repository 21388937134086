/*if (process.env.NODE_ENV !== "production") {
	require("./hmr");
	console.log(`"./hmr": `, "./hmr")

}*/

import * as LazyLoad from 'vanilla-lazyload'

export const myLazyLoad = new LazyLoad({
	elements_selector: '.lazyload',
	class_loaded: 'lazyloaded',
	class_loading: 'lazyloading',
	load_delay: 120,
})

// var myLazyLoad = new LazyLoad({
// 	elements_selector: ".lazyload"
// });

// observer.observe();

// import * as React from "inferno";
// window.lazySizesConfig = window.lazySizesConfig || {}
// // window.lazySizesConfig.lazyClass = 'lazyload'
// window.lazySizesConfig.customMedia = {
// 	'--m': '(max-width: 479px)',
// 	'--d': '(max-width: 1920px)',
// }
// import * as lazysizes from 'lazysizes/lazysizes-umd.min.js'
// lazysizes.init()
// console.log(`lazysizes: `, lazysizes)

import { render, Component } from 'inferno'

// import  JSON5 from 'JSON5/dist/index.min.js'
const JSON5 = require('json5')
// const JSON5 = require('JSON5')
// console.log(`JSON5: `, JSON5)
//
// lazysizes.init()

import { hydrate } from 'inferno-hydrate'
import { App } from './App'
import { BrowserRouter, withRouter } from 'inferno-router'
// import { routess } from "./routess"

// const message = "Hello world!";
// const MyComponent = ({ children, message }) => <div>{message}{children}</div>;

// const data = require('../json/prices.json')
// console.log(`list: `, data)
class ScrollToTopOnMount extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {
		return null
	}
}
class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		console.log(`this.props.location: `, this.props)

		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
			console.log(`'componentDidUpdate': `, 'componentDidUpdate')
		}
		myLazyLoad.update()
	}
	componentDidMount() {
		// 		console.log(`'ScrollToTop': `, 'componentDidMount')

		myLazyLoad.update()
	}

	render() {
		return this.props.children
	}
}
const WScrollToTop = withRouter(ScrollToTop)

export async function rend() {
	// @ts-ignore
	const response = await fetch('../json/routes.json5')
	let routess = await response.text()
	routess = JSON5.parse(routess)
	// 	.map(el => {
	// 	el.text = el.component
	// 	delete el.hidden
	// 	el.children =
	// 		el.loadData &&
	// 		el.loadData.map(el => {
	// 			delete el.hidden
	// 			el.text = el.Comp
	// 			return el
	// 		})
	// 	return el
	// })
	// console.log(`routess: `, routess, response)
	// lazysizes.init()
	// const data = await import('../json/prices.json')

	// const { routes } = await import('./routes')
	// console.log(`data: `, routes)
	// 	console.log(`rend(): `, 'rend()')
	myLazyLoad.update()

	let qq = process.env.NODE_ENV == 'production' ? hydrate : render
	qq(
		<BrowserRouter>
			<WScrollToTop>
				<App routes={routess} />
			</WScrollToTop>
		</BrowserRouter>,
		// @ts-ignore
		document.getElementById('app')
	)
}

rend().catch()

if (browserSupportsAllFeatures()) {
	// Browsers that support all features run `main()` immediately.
	main();
} else {
	// All other browsers loads polyfills and then run `main()`.
	loadScript('https://polyfill.io/v2/polyfill.min.js?features=IntersectionObserver', main);
}
function main(err) {
	// Initiate all other code paths.
	// If there's an error loading the polyfills, handle that
	// case gracefully and track that the error occurred.
}

function browserSupportsAllFeatures() {
	// return window.Promise && window.fetch && window.Symbol ;
	return window.IntersectionObserver
}

function loadScript(src, done) {
	var js = document.createElement('script');
	js.src = src;
	js.onload = function() {
		done();
	};
	js.onerror = function() {
		done(new Error('Failed to load script ' + src));
	};
	document.head.appendChild(js);
}