import * as React from 'inferno'
import { Bg_section } from './Bg'


export const Promo = ({ title, subtitle, bg=null, ...rest }) => (
	<Bg_section className="promo" bg={bg} {...rest}>
		<div>
			<h2 class="gr">{title}</h2>
			<h3>{subtitle}</h3>
		</div>
	</Bg_section>
)
export const PromoGr = ({ title, subtitle, bg, ...rest }) => (
	<Bg_section className="promo grunge" bg={bg} {...rest}>
		<div>
			<h2 class="gr ptsn">{title}</h2>
			<h3 class="ptsn" dangerouslySetInnerHTML={{__html: subtitle}}/>
		</div>
	</Bg_section>
)
export const PromoGr1 = ({ title, subtitle, bg, ...rest }) => (
	<Bg_section className="promo grunge1" bg={bg} {...rest}>
		<div>
			<h2 class="gr ptsn ttn">{title}</h2>
			<h3 class="ptsn">{subtitle}</h3>
			<a className="book ptsn">Забронировать игру</a>
		</div>
	</Bg_section>
)
