// const atob = str => Buffer.from(str, 'base64').toString('binary')

const urlSafeBase64 = string => {
	// console.log(`this.exports: `, this.exports)
	// console.log(`FuseBox.isBrowser: `, FuseBox.isBrowser)

	// if (typeof exports === 'undefined' && this.exports === exports)
	// @ts-ignore
	if (FuseBox.isBrowser)
		return btoa(string)
			.replace(/=/g, '')
			.replace(/\+/g, '-')
			.replace(/\//g, '_')
	// @ts-ignore
	if (!FuseBox.isBrowser)
		return Buffer.from(string)
			.toString('base64')
			.replace(/=/g, '')
			.replace(/\+/g, '-')
			.replace(/\//g, '_')
}

const serv = `https://img.cheremushki-str.ru`
// const serv = `/imgs/`
const loc = process.env.NODE_ENV==='production'?`/4estr.ru/`:'/hotel-str.test/'
// export const Rs = ({ img, w, h, e, g = 'ce', rs = 'fill', sh = 0, bl = 0, q=70 }) => {
export const Rs = (img, opts) => {
	// console.log(`opts: `, opts)

	return (
		serv +'/test'+
		// `rs:fill/${opts || ''}/plain/${'hotel-str.com' + img}?nocache=1`
		`/${opts || ''}/plain/${'https://4estr.ru' + img}`
	)

	// return serv +
	// 	`preset:sharp/rs:${rs}:${w}:${h}:${e}/g:${g}/sh:${sh}/bl:${bl}/q:${q}/plain/${
	// 	'local:///hotel-str.com' + img
	// 		}`
}
// export const Rs = ({ img, w = 900, h = 0, e = 0, g = 'ce', rs = 'fill' }) =>
// 	serv +
// 	`preset:sharp/rs:${rs}:${w}:${h}:${e}/g:${g}/${(
// 		'local:///hotel-str.com' + img
// 	)}`
// `preset:sharp/rs:${rs}:${w}:${h}:${e}/g:${g}/sh:${sh}/bl:${bl}/q:${q}/${urlSafeBase64(
// 	'local:///hotel-str.com' + img
// )}`
