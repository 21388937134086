import * as React from 'inferno'
import { Bg_section } from './Bg'
import { Component } from 'inferno'
import { promises } from 'fs'
// var DG = require('2gis-maps');
// declare var DG:Promise<any>
// console.log(`DG: `, DG)
// var map
export class Map extends Component<{ title: any; subtitle: any; bg: null }> {
	static defaultProps = { bg: null }
	map: any

	componentDidMount() {
		console.log(`n: `)

		// @ts-ignore
		import('https://maps.api.2gis.ru/2.0/loader.js?pkg=basic&lazy=true1&skin=dark').then(
			DG1 => {
				console.log(`DG: `, DG)

				// console.log(DG);
				// @ts-ignore
				DG.then(() => {
					// @ts-ignore
					this.map = DG.map('map', {
						center: [53.582768, 55.996934],
						zoom: 13,
						scrollWheelZoom: false,
						// dragging:false
					})

					let myIcon = DG.icon({
						iconUrl: '/img/map_4e.svg',
						iconAnchor: [32, 84],
						iconSize: [64],
					})

					// @ts-ignore
					DG.marker([53.582768, 55.996934], { icon: myIcon }).addTo(
						this.map
					)
				})
			}
		)
	}

	componentWillUnmount() {
		console.log(`'\tthis.map.remove()': `, '\tthis.map.remove()')
		console.log(`this.map: `, this.map)

		this.map.remove()
	}

	render() {
		let { title, subtitle, bg, ...rest } = this.props
		return (
			<section className="map" {...rest}>
				<div id="map">
					<h2 class="gr">{title}</h2>
					<h3>{subtitle}</h3>
				</div>
			</section>
		)
	}
}
