// import {createElement} from 'react';
import { createElement} from 'inferno-create-element';
import {create} from 'nano-css';
import {addon as addonCache} from 'nano-css/addon/cache'
import {addon as addonStable} from 'nano-css/addon/stable'
import {addon as addonNesting} from 'nano-css/addon/nesting'
import {addon as addonAtoms} from 'nano-css/addon/atoms'
import {addon as addonKeyframes} from 'nano-css/addon/keyframes'
import {addon as addonRule} from 'nano-css/addon/rule'
import {addon as addonSheet} from 'nano-css/addon/sheet'
import {addon as addonJsx} from 'nano-css/addon/jsx'
// import {addon as addonSourcemaps} from './utils/sm'

const nano = create({
	// Add prefix to all generated class names.
	// pfx: 'my-company-',

	// Set hyperscript function of your virtual DOM library, for jsx() addon.
	h: createElement,
});

// Add addons you would like to use.
addonCache(nano);
addonStable(nano);
// addonNesting(nano);
// addonAtoms(nano);
// addonKeyframes(nano);
addonRule(nano);
// addonSheet(nano);
addonJsx(nano);

const {rule, sheet, jsx, keyframes} = nano;

// @ts-ignore
if (process.env.NODE_ENV !== 'production' && FuseBox.isBrowser) {
	// addonSourcemaps(nano);
}
// Export everything.
export {
	nano,
	rule,
	sheet,
	jsx,
	keyframes,
};