import { Route } from "inferno-router"

const Status = ({ code, children }) => (
	<Route
		render={({ staticContext }) => {
			if (staticContext) staticContext.statusCode = code;
			console.log(`staticContext: `, staticContext)

			return children;
		}}
	/>
);
export const e404
= () => (
	<Status code={404}>
	<h1>404</h1>
	</Status>
)