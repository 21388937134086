import { Link, NavLink } from 'inferno-router'
// import * as cyrillicToTranslit from 'cyrillic-to-translit-js'
export const BottomMenu = ({ routes }) => (
	<nav className="bottommenu">
		{routes
			.filter(r=>!r.hidden&&r.path)
			.map((route, i) => (
			//{/*<NavLink exact={route.exact} to={route.path||cyrillicToTranslit().transform(route.menuTitle)}>*/}
			<NavLink exact={route.exact} to={route.path}>
				{/*<span>{route.menuTitle || route.component}</span>*/}
				{route.menuTitle || route.component}
			</NavLink>
		))}
	</nav>
)
// 	<NavLink to="/" className="logo">
// 	<img src="/css/logo.svg" alt=""/>
// 	</NavLink>
// 	<NavLink to="/admin">Снаряжение</NavLink>
// 	<NavLink to="/admin/qq">admin qq</NavLink>
// <NavLink to="/grid">grid</NavLink>
// 	<NavLink to="/grid/bus">Правила</NavLink>
// 	<NavLink to="/grid/cart">Контакты</NavLink>
