import * as React from 'inferno'
import { Component } from 'inferno'
import * as VanillaTilt from 'vanilla-tilt'
import { Bg, Bg_section, BgTilt } from './Bg'
import { Rs } from '../components'

const PriceCell = ({ title, text, price, list, bg, duration, balls }) => (
	<BgTilt className="price-cell" bg={bg} onClick1={e => e}>
		{/*<div className="js-tilt-glare">*/}
		{/*<div className="js-tilt-glare-inner" />*/}
		{/*</div>*/}
		<h3 class="gr1">{title}</h3>
		<h4 class="gr1">
			{price}
			<small>₽</small>
			{/*<div>с игрока</div>*/}
		</h4>
		{/*<div class="duration gr">{duration} минут</div>*/}
		<h5 class="balls gr1">{balls || 100} ШАРОВ</h5>
		<ul class="ptsn pollock1">
			{list.map(el => (
				<li>{el}</li>
			))}
		</ul>

		<a class="book ptsn">Забронировать игру</a>
	</BgTilt>
)

export const PriceTable = ({ title, list = [] }) => {
	// const list = await import('../json/prices.json')
	// console.log(`list: `, list)
	// console.log(`data: `, data)

	return (
		<Bg_section className="price-table border-mask" bg="/img/g3.jpg">
			<h2 class="gr ptsn pollock1">{title}</h2>
			{/*		<div class="h2" contentEditable>
				цены
			</div>*/}
			{/*		<div className="text-effect">
				<h1 className="neon" data-text="цены" contentEditable>цены</h1>
				<div className="gradient"/>
				<div className="spotlight"/>
			</div>*/}
			<div class="snap">
				{list.map(el => (
					<PriceCell
						title={el.title}
						price={el.price}
						duration={el.duration}
						balls={el.balls}
						list={el.list}
						bg={el.bg}
					/>
				))}
			</div>
		</Bg_section>
	)
}

const PriceCell1 = ({
	title,
	text,
	price,
	list,
	img,
	bg = '/img/wooden-wall1.jpg',
	duration,
	balls,
}) => (
	<BgTilt
		className="price-cell eq"
		bg={bg}
		// opts={'/rs:fit/q:68/s:320:320:1'}
		opts={'fit/320/320/sm/0'}
		onClick={e => {
			// e.target.parentElement.style.width='100vw'
			e.target.parentNode.classList.add('zoom')
			return console.log(e)
		}}
	>
		{/*<div className="js-tilt-glare">*/}
		{/*<div className="js-tilt-glare-inner" />*/}
		{/*</div>*/}

		{(price > 0 && (
			<h4 class="gr1">
				{price}
				<small>₽</small>
			</h4>
		)) || <h5 className="gr1">{price}</h5>}
		{/*<div class="duration gr">{duration} минут</div>*/}
		{/*<h5 class="balls gr1">{balls || 100} ШАРОВ</h5>*/}

		<h3 className="gr1 ptsn">{title}</h3>
		<img src={Rs(img, 'w:400')} alt="" />
	</BgTilt>
)
export const PriceTable1 = ({ title, list = [] }) => {
	// const list = await import('../json/prices.json')
	// console.log(`list: `, list)
	// console.log(`data: `, data)

	return (
		<Bg_section className="price-table border-mask" bg="/img/g3.jpg">
			<h2 class="gr">{title}</h2>
			{/*		<div class="h2" contentEditable>
				цены
			</div>*/}
			{/*		<div className="text-effect">
				<h1 className="neon" data-text="цены" contentEditable>цены</h1>
				<div className="gradient"/>
				<div className="spotlight"/>
			</div>*/}
			<div class="snap-flex">
				{list.map(el => (
					<PriceCell1
						title={el.title}
						price={el.price}
						bg={el.bg}
						img={el.img}
					/>
				))}
			</div>
		</Bg_section>
	)
}
