import * as React from 'inferno'
import { Component } from 'inferno'
import * as VanillaTilt from 'vanilla-tilt'
import { Rs } from '../components/Rs'
import { TiltOptions } from 'vanilla-tilt'
import * as lozad from 'lozad'
import { isNode } from '../utils/isNode'

export const BgTilt = ({
	opt = {
		max: 15,
		speed: 1600,
		scale: 1.051,
		glare: true,

		'max-glare': 0.81,
		'glare-prerender': true,
		gyroscope: false, // Boolean to enable/disable device orientation detection,
		gyroscopeMinAngleX: -15, // This is the bottom limit of the device angle on X axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the left border of the element;
		gyroscopeMaxAngleX: 15, // This is the top limit of the device angle on X axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the right border of the element;
		gyroscopeMinAngleY: -15, // This is the bottom limit of the device angle on Y axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the top border of the element;
		gyroscopeMaxAngleY: 15, // This is the top limit of the device angle on Y axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the bottom border of the element;
	},
	bg = '',
	children,
	opts = 'q:71/h:600/w:320',
	...rest
}: {
	opt?: TiltOptions
	bg: string
	children: any
	opts?: string
}) => {
	return (
		<Bg
			bg={bg}
			opts={opts}
			onComponentDidMount={node => VanillaTilt.init(node, opt)}
			onComponentWillUnmount={node => node.vanillaTilt.destroy()}
			{...rest}
		>
			<div className="js-tilt-glare">
				<div className="js-tilt-glare-inner" />
			</div>
			{children}
		</Bg>
	)
}
export const Bg = ({ className, svg, bg, children, opts = 'q:50/w:1280', ...rest }) => {
	// export const Bg = React.forwardRef(({ bg, children, qq, ...rest }, ref) => {
	// 	console.log(`ref: `, ref)
	// 	console.log(`rest: `, rest)

	return (
		<div
			className={'fade lazyload ' + className}
			// ref={ref}
			// style={{ 'background-image': `url(${Rs(bg, opts)})` }}

			// data-background-image={svg ? svg : bg && `${Rs(bg, opts)}`}
			data-bg={bg && `url(${Rs(bg, opts)})`}
			{...rest}
		>
			{/*<img className="fade lazyload bg" data-src={bg && `${Rs(bg, opts)}`} alt=""/>*/}

			{children}
		</div>
	)
}

export const Bg_section = ({
	// className,
	bg,
	children,
	opts = 'q:50/w:1280',
	position = '70% center;',
	...rest
}) => {
	// export const Bg = React.forwardRef(({ bg, children, qq, ...rest }, ref) => {
	// 	console.log(`ref: `, ref)
	// 	console.log(`rest: `, rest)
	// if (!isNode) {
	// 	const observer = lozad('.lozad', {
	// 		loaded(element) {
	// 			element.classList.add('lozaded')
	// 			// element.classList.remove('lozad')
	// 		}
	// 	})
	//
	// observer.observe();
	// }
	return (
		<section
			// className={'fade lazyload ' + className}
			// ref={ref}
			// style={bg && { 'background-image': `url(${Rs(bg, opts)})` }}
			// data-background-image={ bg&&`${Rs(bg, opts)}` }
			// data-bg={bg && `url(${Rs(bg, opts)})`}
			// data-background-image={ bg }
			{...rest}
		>
			{/*<div class="fade lazyload bg" data-bg={bg && `url(${Rs(bg, opts)})`}/>*/}
			{bg && (
				<img
					className="fade lazyload bg"
					data-src={`${Rs(bg, opts)}`}
					alt=""
					style={`object-position:${position}`}
				/>
			)}
			{children}
		</section>
	)
}
{
	/*<>*/
}
{
	/*</>*/
}
{
	/*						<noscript class="no-script">
							<img
								className="fade bg"
								src={`${Rs(bg, opts)}`}
								alt=""
								style={`object-position:${position}`}
							/>
						</noscript>*/
}
