import { Header } from "../blocks"
import { VNodeFlags } from "inferno-vnode-flags"
import { createComponentVNode } from "inferno"
import * as C from '../blocks'

export const Admin = props => {
	return (
			<>
				<Header title={props.menuTitle} />
				{props.loadData.filter(el => !el.hidden).map(El => {
					// const C = (El.c)
					// console.log(`C: `, C)
					// console.log(`El: `, C[El.Comp], El.props)
					// return C[El.Comp](El.props)
					return (
						// pass the sub-routes down to keep nesting
						createComponentVNode(
							// VNodeFlags.ComponentFunction,
							VNodeFlags.ComponentUnknown,
							C[El.Comp],
							El.props
							// {data:route.data,...route.routes}
							// route.data
						)
					)
				})}
			</>
	)
}
