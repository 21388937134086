import * as React from 'inferno'


export const SignInScreen = () => {
  return ;
};

// import StyledFirebaseAuth from '../server/FirebaseAuth'
// import * as firebase from 'firebase'
// const config = {
// 	apiKey: 'AIzaSyDz6KRlaRqIv89F_2fH-x_0YeNXXnOFr6U',
// 	authDomain: 'auth-5ef85.firebaseapp.com',
// 	// ...
// }
// firebase.initializeApp(config)
/*
export class SignInScreen1 extends React.Component {
	state = {
		isSignedIn: false, // Local signed-in state.
	}

	uiConfig = {
		// Popup signin flow rather than redirect flow.
		signInFlow: 'popup',
		// Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
		signInSuccessUrl: '/signedIn',
		// We will display Google and Facebook as auth providers.
		signInOptions: [
			firebase.auth.EmailAuthProvider.PROVIDER_ID,
			// firebase.auth.GoogleAuthProvider.PROVIDER_ID,
			// firebase.auth.FacebookAuthProvider.PROVIDER_ID
		],
		callbacks: {
			// Avoid redirects after sign-in.
			signInSuccessWithAuthResult: () => false,
		},
	}
	// Listen to the Firebase Auth state and set the local state.
	componentDidMount() {
		this.unregisterAuthObserver = firebase
			.auth()
			.onAuthStateChanged(user => this.setState({ isSignedIn: !!user }))
	}
	// Make sure we un-register Firebase observers when the component unmounts.
	componentWillUnmount() {
		this.unregisterAuthObserver()
	}

	render() {
		return (
			<div className="auth">
				<h1>My App</h1>
				{!this.state.isSignedIn ? (
					<>
						<p>Please sign-in:</p>
						<StyledFirebaseAuth
							uiConfig={this.uiConfig}
							firebaseAuth={firebase.auth()}
						/>
					</>
				) : (
					<>
						<p>
							Welcome {firebase.auth().currentUser.displayName}!
							You are now signed-in!
						</p>
						<a onClick={() => firebase.auth().signOut()}>
							Sign-out
						</a>
					</>
				)}
			</div>
		)
	}
}
*/
