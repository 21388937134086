import * as React from 'inferno'
import { Bg, Bg_section } from './Bg'

const onc = e => {
	console.log(`e.target.innerHTML: `, e.target.innerHTML)

	return
}
export const Section2 = ({ title, subtitle, bg = null, ...rest }) => {
	const qq = 1 && { contenteditable: 'true' }

	return (
		<Bg_section className="section2 ptsn" bg={bg} opts="w:1024" {...rest}>
			<Bg className="slider left" bg={'/img/d1.jpg'} opts="w:640" />
			<Bg
				className="right"
				bg={'/img/bg_1.jpg'}
				opts="rs:crop/w:640/g:sm"
			>
				<h2 className="ptsn" {...qq}>
					{title}
				</h2>
				<h3 className="ptsn" onInput={onc} {...qq}>
					{subtitle}
				</h3>
				<a className="book ptsn">Забронировать игру</a>
			</Bg>
		</Bg_section>
	)
}
