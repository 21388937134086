import { Component, createComponentVNode } from 'inferno'
import { Meta } from '../components/Meta'
import * as C from '../blocks'
import { VNodeFlags } from 'inferno-vnode-flags'
import { Header } from '../blocks'

export const Rules = props => {
	// console.log(`props: `, props)
	// console.log(`props: `, props.loadData)
	return (
		<>
			<Header title={props.menuTitle} />
			<section class="grunge contacts">
				<div class="ptsn">
					<h3 class="ptsn">АДРЕС</h3>
					{/*{props.loadData.content}*/}
				</div>
			</section>
		</>
	)
}
