import { Component } from "inferno"
import { isNode } from "../utils/isNode"


export class Meta extends Component {
	componentDidMount() {
		if (!isNode) {
			// document.title = '123123'
			// document.querySelector("meta[name='description']").content='sdfsdfsdf'
		}
	}

	render() {
		console.log(`Meta props: `, this.props)
		console.log(`isNode: `, isNode)
		// if (!isNode) return this.props.children
	}
}

