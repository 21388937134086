import { Link, NavLink } from 'inferno-router'
import { Svg, Svg1, Svg2 } from '../components'
import { NODE_ENV } from '../App'
// import * as cyrillicToTranslit from 'cyrillic-to-translit-js'
export const TopMenu = ({ routes }) => (
	<nav className="topmenu sticky ptsn">
		<NavLink to="/" className="logo">
			<picture className="">
				<source media="(max-width: 479px)" srcset="/img/logo_m.svg" />
				{/*<source media="(min-width: 320px)" srcSet="/img/logo.svg" />*/}
				<img className="" src="/img/logo.svg" alt="" />
			</picture>
			{/*<img src="/img/logo.svg" alt="" />*/}
		</NavLink>
		{routes.filter(r => !r.hidden && r.path).map((route, i) => (
			//{/*<NavLink exact={route.exact} to={route.path||cyrillicToTranslit().transform(route.menuTitle)}>*/}
			<NavLink exact={route.exact} to={route.path}>
				{/*<span>{route.menuTitle || route.component}</span>*/}
				{route.menuTitle || route.component}
			</NavLink>
		))}
		{/*<NavLink to="//admin.4estr.test/" className="tm">*/}
		<div class="tm-right">
			{NODE_ENV !== 'production' && (
				<>
					<a href="//admin.4estr.test/" className="tm">
						<Svg1 id="user" />
					</a>
					<NavLink to="/123" className="tm">
						<Svg1
							id="inst"
						/>
					</NavLink>

				</>
			)}
			<Svg
				className="tm"
				id="Inst_b"
				href="https://www.instagram.com/4estr.ru/"
				target="_blank"
			/>
			<Svg
				className="tm"
				id="vk_b"
				href="https://vk.com/4estr_ru"
				target="_blank"
			/>
		</div>
	</nav>
)
// 	<NavLink to="/" className="logo">
// 	<img src="/img/logo.svg" alt=""/>
// 	</NavLink>
// 	<NavLink to="/admin">Снаряжение</NavLink>
// 	<NavLink to="/admin/qq">admin qq</NavLink>
// <NavLink to="/grid">grid</NavLink>
// 	<NavLink to="/grid/bus">Правила</NavLink>
// 	<NavLink to="/grid/cart">Контакты</NavLink>
