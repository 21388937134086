import { jsx } from './nano'
import { Component, createComponentVNode } from 'inferno'
// import { createElement } from "inferno-create-element";
import { Route, Switch } from 'inferno-router'
import * as C from './blocks'
import * as R from './Routes'
// import { AsyncAdmin } from "./async";
// import Admin from "./Admin";
import { VNodeFlags } from 'inferno-vnode-flags'
// import { withUser, update } from './services/withUser';
// import { Aside } from './admin/Aside'

const Button = jsx(
	'button',
	{
		display: 'inline-block',
		borderRadius: '17px',
		padding: '0.5rem 0',
		margin: '0.5rem 1rem',
		width: '11rem',
		background: 'transparent',
		color: 'white',
		border: '2px solid white',
	},
	'MyButton'
)

// import styles from './cssm.css'
// console.log(`styles: `, styles)

export const NODE_ENV = process.env.NODE_ENV

// import './css.css'
// import './css1.css'

// import { AvatarPicker } from './components/uppy'

// let qq = [Footer, TopMenu, Footer]

const RouteWithSubRoutes = ({ path, exact, routes, component, ...rest }) => (
	<Route
		// exact={true}
		// strict={true}
		path={path}
		// component={route.component}
		render={props => {
			// console.log(`RouteWithSubRoutes props: `, props)
			// console.log(`rest: `, rest)
			// console.log(`C[component]: `, R[component])
			// console.log(`component: `, component)
			// console.log(`exact: `, exact)

			// return <route.component {...props} routes={route.routes} />;
			//todo хуйня какая-то
			// const Comp=R[component]
			// return <Comp {...rest} />
			return (
				// pass the sub-routes down to keep nesting
				createComponentVNode(
					// VNodeFlags.ComponentFunction,
					VNodeFlags.ComponentUnknown,
					R[component],
					rest
					// {data:route.data,...route.routes}
					// route.data
				)
			)
		}}
	/>
)

// const urlSafeBase64 = string => {
// 	return Buffer.from(string)
// 		.toString('base64')
// 		.replace(/=/g, '')
// 		.replace(/\+/g, '-')
// 		.replace(/\//g, '_')
// }

// let zz = `https://img.hotel-str.test/preset:sharp/resize:fill:2000:5000:0/gravity:ce/${urlSafeBase64(
// 	'local:///hotel-str.com/css/color-burst-1.jpg'
// )}`
// console.log(`zz: `, zz)

// const ww = qq => {
// 	fetch(zz, { mode: 'no-cors', method: 'GET', headers: { 'x-update': '1' } })
// 	fetch(zz + '.jpg', {
// 		mode: 'no-cors',
// 		method: 'GET',
// 		headers: { 'x-update': '1' },
// 	})
// 	return
// }
// let EventSource = require("eventsource");
// if (isNode) EventSource = require("eventsource")

const JSON5 = require('json5')

export class App extends Component<{ /*data: {};*/ routes: any }> {
	private eventSource: any
	state: {
		routes: []
	}

	// observer
	constructor(props) {
		super(props)
		let q = 1
		this.state = { routes: this.props.routes }
		// this.observer=''
		// if (!isNode) {
		//
		// 	import("./client").then(({observer})=> {
		// 		this.observer = observer
		// 		console.log(`constructor observer: `, this.observer)
		// 	})
		//
		// 	console.log(`constructor observer: `, this.observer)
		//
		// 	// this.observer.observe();
		// }
		// @ts-ignore
	}

	update(d) {
		console.log(`'update': `, 'update', d)

		this.setState({ routes: d })
		// console.log(`this.state: `, this.state)
	}

	componentDidMount() {
		console.log(`'App componentDidMount': `, 'App componentDidMount')

		// @ts-ignore
		// this is going to double check that the user is still actually logged in
		// if the app is reloaded. it's possible that we still have a user in sessionStorage
		// but the user's session cookie expired.
		// axios.get('/api/auth')
		// 	.then(res => {
		// 		// if we get here, the user's session is still good. we'll update the user
		// 		// to make sure we're using the most recent values just in case
		// 		update(res.data);
		// 	})
		// 	.catch(err => {
		// 		// if we get a 401 response, that means the user is no longer logged in
		// 		if (err.response.status === 401) {
		// 			update(null);
		// 		}
		// 	});
		/*		try {
			const response = await fetch(`/json/prices.json`)
			if (!response.ok) {
				throw Error(response.statusText)
			}
			const json = await response.json()
			this.setState({
				data: json,
			})
			console.log(`this.state: `, this.state)
		} catch (error) {
			console.log(error)
		}*/
		// fetch(`/json/prices.json`)
		//  .then(res => res.json())
		//  .then(json => this.setState({ data: json }));
		// const data = await import('../json/prices.json')
	}
	componentWillUnmount(): void {
		// this.eventSource.close()
	}

	handleIntersection(event) {
		console.log(event.isIntersecting)
	}

	render() {
		console.log(`'App render': `, 'App render')
		// console.log(`this.props.routes: `, this.props.routes)
		// if (!isNode&&this.observer) {
		// 	console.log(`this.observer: `, this.observer)
		//
		// 	this.observer.observe()
		// }
		// console.log(`this.state.routes: `, this.state.routes[4])
		// const qq=this.state.routes
		const options = {
			onChange: this.handleIntersection,
			root: '#scrolling-container',
			rootMargin: '0% 0% -25%',
		}

		return (
			<>
				{/*<Button>Button</Button>*/}
				{/*<button className={styles.myClass}>Warp Drive</button>*/}
				{/*<Route*/}
				{/*exact*/}
				{/*path="/"*/}
				{/*render={() => (*/}
				{/*<img*/}
				{/*className="logo"*/}
				{/*src="/4estr_logo2.svg"*/}
				{/*alt=""*/}
				{/*/>*/}
				{/*)}*/}
				{/*/>*/}
				{process.env.NODE_ENV !== 'production'  && <Route component={Aside} />}
				<C.TopMenu routes={this.state.routes} />
				{/*<Route component={Lozad} />*/}
				<Switch>
					{this.state.routes
						.filter(r => r['component'])
						.map((route, i) => (
							<RouteWithSubRoutes
								key={i}
								// data={this.props.data}
								// data={this.state.data.data}
								{...route}
							/>
						))}
				</Switch>

				<C.Footer routes={this.state.routes} />
				{/*<Observer {...options}>*/}
				{/*<div>I am the target element</div>*/}
				{/*</Observer>*/}
				{/*<Route exact path="/admin" component={Admin}/>*/}
				{/*<Route exact path="/grid" component={AsyncButton}/>*/}
				{/*<Route path="/topics" component={Topics}/>*/}

				{/*<AvatarPicker currentAvatar={''} />*/}
				{/*<h1 contentEditable>цены</h1>*/}
				{/*<h3 contentEditable>цены</h3>*/}
				{/*<div contentEditable>цены</div>*/}
			</>
		)
	}
}
