import { Bg } from './Bg'
import { BottomMenu } from './BottomMenu'
import { Svg } from '../components/Svg'
import { Link } from 'inferno-router'

export const Footer = props => (
	<footer class="ptsn hhhh">
		{/*<Bg bg='/css/footer.jpg'>uki</Bg>{'dfg'}*/}
		<div>
			<div className="f-left">
				<h3 className="ptsn">контакты</h3>
				<p>
					<Svg className="phone" id="phone" href="tel:79638903333">
						<span>+7 [963] 890-33-33</span>
					</Svg>
				</p>
				<p>
					<Svg
						className="phone"
						id="email"
						href="mailto:info@che-str.ru"
						target="_blank"
					>
						<span>info@che-str.ru</span>
					</Svg>
				</p>
				<p>
					<Svg
						className="phone"
						id="WhatsApp"
						href="https://wa.me/79638903333"
						target="_blank"
					>
						<span>WhatsApp</span>
					</Svg>
				</p>
			</div>
			{/*			<img
				class="lazyload logo_f"
				data-sizes1="(max-width: 479px) 479px,
			100vw"
				data-srcset="css/logo_f1.svg 479w, css/logo.svg 480w"
				alt=""
			/>*/}
			{/*<picture className="lazyload logo_f">*/}
			<picture className="fade logo_f">
				<source
					media="(max-width: 1479px)"
					// media="--m"
					data-srcset="/img/logo_f1.svg"
				/>
				{/*				<source
					// media="(max-width: 479px)"
					media="--d"
					data-srcset="/css/logo.svg"
				/>*/}
				{/*<source media="(min-width: 320px)" srcSet="/css/logo.svg" />*/}
				<img className="lazyload" data-src="/img/logo.svg" alt="" />
			</picture>
			{/*<div className="logo_f"/>*/}
			{/*<Bg svg={'/css/logo_f1.svg'} className="logo_f"/>*/}
			<div className="f-right">
				<h3 className="ptsn">адрес</h3>
				<ul>
					<li>г. Стерлитамак, п. Заливной</li>
					<li>База отдыха "Черёмушки"</li>
				</ul>
				<h3 className="ptsn">мы в соцсетях</h3>
				<p>
					<Svg
						id="Inst_b"
						href="https://www.instagram.com/4estr.ru/"
						target="_blank"
						rel="noopener"
					/>
					<Svg
						id="vk_b"
						href="https://vk.com/4estr_ru"
						target="_blank"
						rel="noopener"
					/>
					{/*					<svg className="icon">
						<use xlinkHref="#Inst_b"/>
					</svg>*/}
				</p>
				{/*
				<h3>Мессенджеры</h3>
				<p>
					<Svg
						id="WhatsApp"
						href="https://wa.me/79638903333"
						target="_blank"
					/>
				</p>
				*/}
			</div>
		</div>
		<BottomMenu routes={props.routes} />
		<p>© {new Date().getFullYear()}.</p>
	</footer>
)
