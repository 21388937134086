import * as React from 'inferno'
import { Bg_section } from './Bg'

export const Promo1 = ({ title, subtitle, bg=null, ...rest }) => (
	<Bg_section className="promo" bg={bg} {...rest}>
		<div>
			<h2 class="gr">{title}</h2>
			<h3>{subtitle}</h3>
		</div>
	</Bg_section>
)
export const PromoGr1 = ({ title, subtitle, bg=null, ...rest }) => (
	<Bg_section className="promo grunge" bg={bg} {...rest}>
		<div>
			<h2 class="gr">{title}</h2>
			<h3>{subtitle}</h3>
		</div>
	</Bg_section>
)
