import { Component, createComponentVNode } from 'inferno'
import { Meta } from '../components/Meta'
import * as C from '../blocks'
import { VNodeFlags } from 'inferno-vnode-flags'
import { Header } from '../blocks'
import { SignInScreen } from "./Auth"

export const Contacts = props => {
	// console.log(`props: `, props)
	// console.log(`props: `, props.loadData)
	return (
		<>
			<SignInScreen/>
			<Header title={props.menuTitle} />
			<section class="grunge contacts">
				<div class="ptsn">
					<h3 class="ptsn">АДРЕС</h3>
					<p>
						{'г.Стерлитамак, п. Заливной\nБаза отдыха "Черёмушки"'}
					</p>
				</div>
				<div class="ptsn">
					<h3 class="ptsn">ТЕЛЕФОН</h3>
					<p>+7 [963] 890-33-33</p>
				</div>
				<div class="ptsn">
					<h3 class="ptsn">МЕССЕНДЖЕРЫ</h3>
					<p>+7 [963] 890-33-33</p>
				</div>
				<div class="ptsn">
					<h3 class="ptsn">ЧАСЫ РАБОТЫ</h3>
					<p>10:00-20:00</p>
				</div>
			</section>
			<C.Map title="Карта" />
		</>
	)
	return (
		<>
			{props.loadData.map(El => {
				// const C = (El.c)
				// console.log(`C: `, C)
				// console.log(`El: `, C[El.Comp], El.props)
				// return C[El.Comp](El.props)
				return (
					// pass the sub-routes down to keep nesting
					createComponentVNode(
						// VNodeFlags.ComponentFunction,
						VNodeFlags.ComponentUnknown,
						C[El.Comp],
						El.props
						// {data:route.data,...route.routes}
						// route.data
					)
				)
			})}
		</>
	)
}
{
	/*
			<Meta>
				<title>dfgdsfgsdf</title>
				<meta name="description" content="Some description." />
			</Meta>
*/
}

{
	/*			<Promo title="Акции" subtitle="По понедельникам и четвергам" bg="/uploads/2017/04/bg_1-id=374.jpg">
				Home
			</Promo>
			<PriceTable {...props} />
			<Promo title="Акции" subtitle="По понедельникам и четвергам" bg="/uploads/2017/04/bg_1-id=374.jpg">
				Home
			</Promo>
			<section className="promo grunge">
				<div>
					<h2 className="gr">{'title'}</h2>
					<h3>{'subtitle'}</h3>
				</div>
			</section>
			<PromoGr title="Акции" subtitle="По понедельникам и четвергам" bg="/uploads/2017/04/bg_3-id=333.jpg">
				Home
			</PromoGr>

			<Promo title="Акции" subtitle="По понедельникам и четвергам">
				Home
			</Promo>*/
}
