export * from "./Bg";
export * from "./BottomMenu";
export * from "./Footer";
export * from "./Header";
export * from "./Map";
export * from "./PriceTable";
export * from "./Promo";
export * from "./Promo1";
export * from "./Section2";
export * from "./TopMenu";
