import { Component, createComponentVNode } from 'inferno'
// import { Footer, TopMenu, Promo, PromoGr, PriceTable } from '../blocks'
import { Meta } from '../components/Meta'
import * as C from '../blocks'
import { VNodeFlags } from 'inferno-vnode-flags'
// const qq = { Footer, TopMenu, Promo, PromoGr }
// console.log(`qq: `, qq)

// import qq from 'Home.json'
// import ('Home.json')
// console.log(`qq: `, qq)

/*export class Home extends Component {
	constructor(props) {
		super(props)
		let q = 1
		this.state = { data: [] }
	}
	async componentDidMount() {
		// try {
		// 	const response = await fetch(`/json/Home.json`)
		// 	if (!response.ok) {
		// 		throw Error(response.statusText)
		// 	}
		// 	const json = await response.json()
		// 	this.setState({
		// 		data: json,
		// 	})
		//
		// 	console.log(`this.state: `, this.state)
		// } catch (error) {
		// 	console.log(error)
		// }


		// fetch(`/json/prices.json`)
		//  .then(res => res.json())
		//  .then(json => this.setState({ data: json }));

		// const data = await import('../json/prices.json')
	}

	render() {
		return <>{this.state.data.map(El => {
		// return <>{this.props.value.map(El => {
			const C = (El.c)
			console.log(`C: `, C)

			return qq[C](El.props)
		})}</>
		return <p>"."</p>
	}

}*/

export const Home = props => {
	// console.log(`props: `, props)
	// console.log(`props: `, props.loadData)

	return (
		<>
			<img className="logo" src="/4estr_logo2.svg" alt="" />
			{props.loadData.filter(el => !el.hidden).map(El => {
				// const C = (El.c)
				// console.log(`C: `, C)
				// console.log(`El: `, C[El.Comp], El.props)
				// return C[El.Comp](El.props)
				return (
					// pass the sub-routes down to keep nesting
					createComponentVNode(
						// VNodeFlags.ComponentFunction,
						VNodeFlags.ComponentUnknown,
						C[El.Comp],
						El.props
						// {data:route.data,...route.routes}
						// route.data
					)
				)
			})}
		</>
	)
}
{
	/*
			<Meta>
				<title>dfgdsfgsdf</title>
				<meta name="description" content="Some description." />
			</Meta>
*/
}

{
	/*			<Promo title="Акции" subtitle="По понедельникам и четвергам" bg="/uploads/2017/04/bg_1-id=374.jpg">
				Home
			</Promo>
			<PriceTable {...props} />
			<Promo title="Акции" subtitle="По понедельникам и четвергам" bg="/uploads/2017/04/bg_1-id=374.jpg">
				Home
			</Promo>
			<section className="promo grunge">
				<div>
					<h2 className="gr">{'title'}</h2>
					<h3>{'subtitle'}</h3>
				</div>
			</section>
			<PromoGr title="Акции" subtitle="По понедельникам и четвергам" bg="/uploads/2017/04/bg_3-id=333.jpg">
				Home
			</PromoGr>

			<Promo title="Акции" subtitle="По понедельникам и четвергам">
				Home
			</Promo>*/
}
